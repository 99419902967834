export const UPDATEPASSWORD = 'Thay đổi mật khẩu';

export const LOGOUT = 'Đăng xuất';

export const PROFILE = 'Thông tin cá nhân';

export const ACTIVE = 1;

export const ISACTIVE = true;

export const FROM_DATE = '1';
export const MONTH_YEAR = '2';
export const YEAR = '4';
export const QUARTER = '3';

export const QUARTER_1 = '1';
export const QUARTER_2 = '2';
export const QUARTER_3 = '3';

export const LATEST_MONTH_QUARTER_1 = 3;
export const LATEST_MONTH_QUARTER_2 = 6;
export const LATEST_MONTH_QUARTER_3 = 9;

export const ACCESSS_TOKEN = 'token';
export const WAITTING_DRIVER = 0;
export const IS_BLOCK = 3;

export const DEFAULT_REGION = 'Mặc định';
export const BG_NOTIFICATION = '#cfdbe7';
export const MINDATE = new Date(new Date().getFullYear() - 100, 0, 1);

// ENUM TRIP

export const NEW = 0;
export const RECEIVED = 1;
export const DriverhasArrived = 2;
export const INPROCESS = 3;
export const FINISHED = 4;
export const COMPLETE = 5;
export const CANCEL = 6;
export const TRIP_SUCCESS = '#228511';
export const TRIP_CANCEL = '#c82222';

// ENUM NOTIFICATION
export const ReceiveMessageBookADriver = 0;
export const ReceiveMessageWhenDriverAccept = 2;
export const ReceiveMessageWhenDriverRefuses = 1;
export const ReceiveMessageWhenCustomerOrDriverCancelTrip = 6;
export const ReceiveMessageWhenDriverStartWaiting = 8;
export const ReceiveMessageWhenDriverFinishedWaiting = 9;
export const ReceiveMessageWhenDriverFilldWaitingFee = 10;
export const ReceiveMessageWhenDriverNearBy = 11;

// TRIP POINT
export const END = 'end';
export const STOP = 'stop';
export const UNKNOW = 'Chưa xác định';

export const DRIVER_ACTIVE = 1;
export const DRIVER_IS_COMING_FINISH = 2;

export const DEFAULT_CENTER_MAP = { lat: 16.4613012121212158, lng: 107.612121212166094 };

// PRIVACY POLICY
export const CONTACT_INFO = {
  textRole: 'Khách hàng',
  email: 'Gosafe@gmail.com',
  phone: '0374180180',
  address: '71 Ngô Thì Hương, Phường Nại Hiên Đông, Quận Sơn Trà, Thành phố Đà Nẵng',
  workingHours: 'Từ 8 Sáng - 17 Chiều ( Từ T2 - T7, Chủ nhật nghỉ )',
};

export const ROLE_USER = 'Người dùng';
export const ROLE_DRIVER = 'Tài xế';

export const GOSAFE_USER = 'GOSafe';
export const GOSAFE_DRIVER = 'GOSafe for Driver';

// REQUEST_DELETE_ACCOUNT
export const IS_REQUEST_DELETE_DRIVER = 1;
export const IS_REQUEST_DELETE_USER = 2;
